<template>
  <v-tabs
    v-model="tab"
    class="px-2"
    style="border-radius: 10px;"
  >
    <v-tab
      class="font-weight-bold text-caption"
    >
      <v-badge
        :color="communityCounter ? 'primary' : 'transparent'"
        :content="communityCounter"
      >
        {{ $t('navigation.community') }}
      </v-badge>
    </v-tab>
    <v-tab
      class="font-weight-bold text-caption"
    >
      <v-badge
        :color="personaCounter ? 'primary' : 'transparent'"
        :content="personaCounter"
      >
        {{ $t('navigation.persona') }}
      </v-badge>
    </v-tab>
    <v-tab
      class="font-weight-bold text-caption"
    >
      <v-badge
        :color="profileCounter ? 'primary' : 'transparent'"
        :content="profileCounter"
      >
        {{ $t('navigation.user') }}
      </v-badge>
    </v-tab>
  </v-tabs>
</template>

<script>
  import SearchService from '@api/search'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      setSelected: Function,
      query: String
    },

    watch: {
      tab: {
        handler(tab) {
          const label = tab === 0 ? 'community' : tab === 1 ? 'persona' : 'profile'
          this.setSelected(label)
        }
      },
      query: {
        handler(query) {
          if (query) {
            this.updateCounters(query)
          }
        }
      }
    },

    async created() {
      this.searchService = SearchService(this.user.id)
      if (this.query) {
        await this.updateCounters(this.query)
      }
    },

    data() {
      return {
        tab: 0,
        searchService: null,
        communityCounter: 0,
        personaCounter: 0,
        profileCounter: 0
      }
    },

    computed: {
      ...mapGetters({
        user: 'user'
      })
    },

    methods: {
      async updateCounters(query) {
        const defaultQuery = {
          name: {
            $regex: query,
            $options: 'i',
          }
        }
        const [communityCounter, personaCounter, profileCounter] = await Promise.all([
          this.searchService.communityCount(defaultQuery),
          this.searchService.personaCount(defaultQuery),
          this.searchService.profileCount(defaultQuery)
        ])
        this.communityCounter = communityCounter
        this.personaCounter = personaCounter
        this.profileCounter = profileCounter
      }
    }
  }
</script>