<template>
  <ScrollPagination
    :requestData="{
      'url': `communities/${this.user.id}`,
      'params': {
        query: {
          name: {
            $regex: query,
            $options: 'i',
          }
        }
      }
    }"
    :countData="{
      'url': `communities/count`,
    }"
    :rowClass="`width: 100%; justify-content: space-between;`"
  >
    <template v-slot:child="props">
      <CommunityCard
        :key="props.item._id"
        :id="props.item.id"
        :thumbnail="props.item.thumbnail"
        :name="props.item.name"
        :about="props.item.about"
        class="mb-4"
      />
    </template>
  </ScrollPagination>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ScrollPagination from '@components/App/ScrollPagination.vue'
  import CommunityCard from '@components/Community/Card.vue'

  export default {
    components: {
      ScrollPagination,
      CommunityCard
    },

    props: {
      query: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapGetters({
        user: 'user'
      })
    }
  }
</script>