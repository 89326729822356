import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    communityCount (params) {
      return apiRequest.get(
        `communities/${userId}/count`,
        { params }
      )
    },
    personaCount (params) {
      return apiRequest.get(
        `personas/count`,
        { params }
      )
    },
    profileCount (params) {
      return apiRequest.get(
        `profiles/count`,
        { params }
      )
    }
  }
}
