<template>
  <v-container>
    <v-row>
      <FilterTab
        :query="query"
        :setSelected="setSelected"
      />
    </v-row>
    <v-row v-if="contentToShow === 'community'">
      <CommunityList
        :query="query"
      />
    </v-row>
    <v-row v-else-if="contentToShow === 'persona'">
      <PersonaList
        :query="query"
      />
    </v-row>
    <v-row v-else-if="contentToShow === 'profile'">
      <ProfileList
        :query="query"
      />
    </v-row>  
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import FilterTab from '@components/Search/FilterTab.vue';
  import CommunityList from '@components/Search/CommunityList.vue';
  import PersonaList from '@components/Search/PersonaList.vue';
  import ProfileList from '@components/Search/ProfileList.vue';

  import {
    mdiAccountGroup,
    mdiDramaMasks,
    mdiShimmer
  } from '@mdi/js'

  export default {
    name: 'Search',

    components: {
      FilterTab,
      CommunityList,
      PersonaList,
      ProfileList
    },

    data() {
      return {
        mdiAccountGroup,
        mdiDramaMasks,
        mdiShimmer,
        contentToShow: 'community',
        query: this.$route.query.q
      }
    },

    watch: {
      $route(to, from) {
        const value = to.query.q
        const oldValue = from.query.q
        if (!value || value === oldValue) return
        this.query = value
      }
    },

    computed: {
      ...mapGetters({
        annaChamber: 'annaChamber'
      })
    },

    methods: {
      ...mapMutations({
        forceCloseAnnaChamber: 'forceCloseAnnaChamber'
      }),
      setSelected(value) {
        this.contentToShow = value
      },
      fetch() {

      }
    }
  }
</script>