var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollPagination',{attrs:{"requestData":{
    'url': "personas",
    'params': {
      query: {
        name: {
          $regex: _vm.query,
          $options: 'i',
        }
      }
    }
  },"countData":{
    'url': "personas/count",
  },"rowClass":"width: 100%;","rootStyle":"width: 100%;"},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('v-col',{key:("persona_card_" + (props.index)),staticClass:"py-0 px-0",attrs:{"cols":"12","xs":"6","sm":"4"}},[_c('PersonaCard',{attrs:{"persona":props.item}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }