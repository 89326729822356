<template>
  <ScrollPagination
    :requestData="{
      'url': `personas`,
      'params': {
        query: {
          name: {
            $regex: query,
            $options: 'i',
          }
        }
      }
    }"
    :countData="{
      'url': `personas/count`,
    }"
    :rowClass="`width: 100%;`"
    :rootStyle="`width: 100%;`"
  >
    <template v-slot:child="props">
      <v-col
        :key="`persona_card_${props.index}`"
        class="py-0 px-0"
        cols="12" xs="6" sm="4"
      >
        <PersonaCard :persona="props.item"></PersonaCard>
      </v-col>
    </template>
  </ScrollPagination>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ScrollPagination from '@components/App/ScrollPagination.vue'
  import PersonaCard from '@components/Persona/Card'

  export default {
    components: {
      ScrollPagination,
      PersonaCard
    },

    props: {
      query: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapGetters({
        user: 'user'
      })
    }
  }
</script>