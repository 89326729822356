<template>
  <Scrollbar
    :thin="true"
    class="px-4"
    :style="`width: 100%; height: ${innerHeight - 150}px;`"
  >
  <v-container>
    <v-row>
      <v-col
        v-for="(profile, index) in items"
        :xs="12"
        :sm="6"
        :md="4"
        :key="`profile_col_${profile._id}`"
      >
        <ProfileCard
          :key="`profile_${index}`"
          class="mb-4"
          style="width: 100%;"
          :idx="index"
          :person="profile"
          @newSenderRequest="addSenderRequest"
        />
      </v-col>
    </v-row>
  </v-container>
  </Scrollbar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Scrollbar } from '@components/App'
  import ProfileCard from '@components/Profiles/Card.vue'
  import { mongoSelect } from '@utils'
  import ProfileService from '@api/profile'

  export default {
    components: {
      Scrollbar,
      ProfileCard
    },

    watch: {
      query: {
        async handler(q) {
          this.items = await this.loadProfiles()
        }
      }
    },

    data() {
      return {
        items: [],
        profileService: null
      }
    },

    async created() {
      this.profileService = ProfileService(this.user.id)
      this.items = await this.loadProfiles()
    },

    props: {
      query: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        innerHeight: 'innerHeight',
      })
    },

    methods: {
      addSenderRequest(friendship) {
        const idx = this.items.map(person => person._id).indexOf(friendship.recipient)
        if (idx != -1) this.items[idx].friendship = friendship
      },
      async loadProfiles() {
        return this.profileService.list({
          query: {
            name: {
              $regex: this.query,
              $options: 'i',
            },
            profile: this.profileId,
            isValid: true,
            isActive: true,
            _id: {
              $ne: this.profileId,
            },
            username: {
              $ne: 'anna'
            }
          },
          select: mongoSelect.profiles
        })
      }
    }
  }
</script>