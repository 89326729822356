var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollPagination',{attrs:{"requestData":{
    'url': ("communities/" + (this.user.id)),
    'params': {
      query: {
        name: {
          $regex: _vm.query,
          $options: 'i',
        }
      }
    }
  },"countData":{
    'url': "communities/count",
  },"rowClass":"width: 100%; justify-content: space-between;"},scopedSlots:_vm._u([{key:"child",fn:function(props){return [_c('CommunityCard',{key:props.item._id,staticClass:"mb-4",attrs:{"id":props.item.id,"thumbnail":props.item.thumbnail,"name":props.item.name,"about":props.item.about}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }